import React, { useEffect, useState } from "react";
import { ServiceType } from "__generated__/globalTypes";
import { ModelMenuAddon } from "@model/DAO/MenuAddon";
import { ModelMenuAddonItem } from "@model/DAO/MenuAddonItem";
import NumberFormat from "react-number-format";
import { MmsMuiInput } from "@uiKit/Atoms/Inputs/MmsMuiInput";
import { withFormik } from "formik";
import { styles } from "./PricesForm.styles";
import {
  MenuItemPricesData,
  ServiceTypePricesData,
} from "@model/helperTypes/Prices";
import {
  getInitialPrice,
  getServiceTypePricesData,
  getTypeName,
} from "./utils";
import { useTranslation } from "react-i18next";

interface Props {
  type: ServiceType;
  pricesData: MenuItemPricesData;
  priceLevelAddon: ModelMenuAddon | undefined;
  enabled: boolean;
  onChange: (data: ServiceTypePricesData) => void;
}

const ServiceTypePricesSectionContent: React.FC<Props> = ({
  type,
  pricesData,
  priceLevelAddon,
  enabled,
  onChange,
}) => {
  const { t } = useTranslation(["menu"]);
  const [serviceTypePrice, setServiceTypePrice] = useState(
    getInitialPrice(type, pricesData)
  );
  const [priceLevelPrices, setPriceLevelPrices] = useState(
    priceLevelAddon?.items.map((level: ModelMenuAddonItem, index) =>
      getInitialPrice(type, pricesData, index + 1)
    )
  );
  const setPriceLevelLevelPrice = (newPrice: string, index: number) =>
    setPriceLevelPrices(
      priceLevelPrices?.map(
        (currentPrice: string | null | undefined, i: number) =>
          i === index ? newPrice : currentPrice
      )
    );

  useEffect(() => {
    onChange(
      getServiceTypePricesData(type, {
        price: serviceTypePrice,
        addonPrices: priceLevelPrices,
      })
    );
    // eslint-disable-next-line
  }, [serviceTypePrice, priceLevelPrices]);

  return (
    <div className={styles.serviceTypeSection}>
      <div className={styles.row}>
        {!priceLevelAddon ? (
          <NumberFormat
            name={`price${getTypeName(type)}`}
            customInput={MmsMuiInput}
            disabled={!enabled}
            decimalScale={2}
            label={t("menu:label-price")}
            allowNegative={false}
            value={serviceTypePrice || ""}
            onChange={(e) => setServiceTypePrice(e.target.value)}
          />
        ) : (
          (priceLevelAddon.items as ModelMenuAddonItem[]).map(
            (level, index) => (
              <NumberFormat
                key={index}
                name={`priceLevel${index + 1}${getTypeName(type)}`}
                customInput={MmsMuiInput}
                disabled={!enabled}
                decimalScale={2}
                label={level.title}
                allowNegative={false}
                value={priceLevelPrices?.[index] || ""}
                onChange={(e) => setPriceLevelLevelPrice(e.target.value, index)}
              />
            )
          )
        )}
      </div>
    </div>
  );
};

export const ServiceTypePricesSection = withFormik<
  Props,
  ServiceTypePricesData
>({
  handleSubmit: (values) => {},
})(ServiceTypePricesSectionContent);
