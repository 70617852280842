import { useDidUpdate, useForceReloadOnUpdate, useOpenClose } from "@hooks";
import { ModelLocalAddon } from "@model/DAO/MenuAddon";
import { ModelLocalAddonItem } from "@model/DAO/MenuAddonItem";
import {
  AddonStrategyEnum,
  AddonTypeEnum,
  LocalAddonItemsMeta,
} from "@model/helperTypes/Addon";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";
import { RerankableEntity } from "@model/helperTypes/dragAndDrop";
import { LevelType } from "@model/helperTypes/PriceLevelPrices";
import { getActiveEntities } from "@pages/RestaurantMenuPage/utils/GenericEntity";
import { SortableList } from "@uiKit/Layouts/SortableList";
import { MmsAlert } from "@uiKit/Molecules/Modals/MmsAlert";
import { activeAddonItemsSettingsVar } from "@utils/apolloReactiveVars";
import { noop } from "@utils/noop";
import { useField } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocalAddonItems } from "../../hooks/useLocalAddonItems";
import { useNewAddonItem } from "../../hooks/useNewAddonItem";
import {
  checkPreparingNewAddonItemAvailable,
  decomposePriceFields,
  generatePriceListData,
  getVisiblePriceLevels,
} from "../../utils/AddonItem";
import { AutoenableSettings } from "../AutoenableSettings/AutoenableSettings";
import { AddonItem, AddonItemProps } from "./AddonItem/AddonItem";
import { styles } from "./AddonItemsSettings.styles";
import { AddonItemsEmptyList } from "./EmptyList/EmptyList";
import { useTranslation } from "react-i18next";

export interface AddonItemsSettingsBaseProps {
  addonsSettingsStrategy: AddonsSettingsStrategyEnum;
  addon: ModelLocalAddon;
  addonType: AddonTypeEnum;
  addonStrategy: AddonStrategyEnum;
  activePriceLevelAddon?: ModelLocalAddon;
  isPriceLevelDependent: boolean;
  isUpdateProcessing: boolean;
  min?: number;
  max?: number;
  updateAddonItems: (
    addonId: string,
    newItemsMeta: LocalAddonItemsMeta
  ) => void;
}

export interface AddonItemsSettingsProps extends AddonItemsSettingsBaseProps {
  fieldName: string;
  validate?: (value: any) => undefined | string | Promise<any>;
}

export const AddonItemsSettings: React.FC<AddonItemsSettingsProps> = ({
  addonsSettingsStrategy,
  fieldName,
  addon,
  addonType,
  addonStrategy,
  activePriceLevelAddon,
  isPriceLevelDependent,
  isUpdateProcessing,
  updateAddonItems,
  validate,
}) => {
  const [field, meta, helpers] = useField({ name: fieldName, validate });

  const { t } = useTranslation(["addons", "common"]);

  const [
    { localAddonItems, newAddonItems, removedAddonItems, changedAddonItems },
    { create, clone, remove, toggle, changeTextFields, reorder },
  ] = useLocalAddonItems(field.value);

  const visiblePriceLevels: LevelType[] = useMemo(() => {
    if (!activePriceLevelAddon) return [];
    return getVisiblePriceLevels(
      activePriceLevelAddon.itemsMeta.localAddonItems
    );
  }, [activePriceLevelAddon]);

  const visiblePriceLevelAddonItems: ModelLocalAddonItem[] = useMemo(() => {
    if (!activePriceLevelAddon) return [];
    return getActiveEntities(activePriceLevelAddon.itemsMeta.localAddonItems);
  }, [activePriceLevelAddon]);

  const [
    isActivePriceLevelAddonItemAlertOpen,
    openActivePriceLevelAddonItemsAlert,
    closeActivePriceLevelAddonItemsAlert,
  ] = useOpenClose(false);

  const [
    shouldCreateNewAfterUpdate,
    enableCreateNewAfterUpdate,
    disableCreateNewAfterUpdate,
  ] = useOpenClose(false);

  const [
    newAddonItem,
    {
      clearNewAddonItem,
      toggleNewAddonItem,
      prepareNewAddonItem,
      createNewAddonItem,
    },
  ] = useNewAddonItem({
    addonItemsMeta: field.value,
    addonStrategy,
    visiblePriceLevels,
    create,
  });

  const withProtectionFromDisabledPriceLevelAddonItems = useCallback(
    (fn: (addonItemId: string) => void) => (addonItemId: string) => {
      const tempLocalAddonItems = [...localAddonItems];
      const addonItemIndex = tempLocalAddonItems.findIndex(
        ({ id }) => id === addonItemId
      );
      const [addonItem] = tempLocalAddonItems.splice(addonItemIndex, 1);
      const areThereActiveSiblings = tempLocalAddonItems.filter(
        ({ active }) => active
      ).length;

      // allow action either if addon item belongs to non-priceLevel addon;
      // or for any type of addon, if addon item is not active;
      // or for for priceLevel addon, if ones contains other active items;
      const isActionAllowed = [
        addonType !== AddonTypeEnum.priceLevel,
        !addonItem.active,
        areThereActiveSiblings,
      ].some(Boolean);

      return isActionAllowed
        ? fn(addonItemId)
        : openActivePriceLevelAddonItemsAlert();
    },
    [addonType, localAddonItems, openActivePriceLevelAddonItemsAlert]
  );

  const handleChangeForm = useCallback(
    (addonId: string, newTitle: string, priceFields: any) => {
      const newPriceFields = decomposePriceFields(
        addonStrategy,
        visiblePriceLevels,
        priceFields
      );
      changeTextFields(addonId, newTitle, newPriceFields);
    },
    [addonStrategy, visiblePriceLevels, changeTextFields]
  );

  const isPreparingNewAvailable = useMemo(() => {
    return checkPreparingNewAddonItemAvailable(
      addonType,
      localAddonItems.length
    );
  }, [addonType, localAddonItems.length]);

  const addonItemsSettingsKey = useForceReloadOnUpdate([
    addonType,
    isPriceLevelDependent,
  ]);

  const handleChangeOrder = useCallback(
    (data: AddonItemProps[]) => {
      const reorderedLocalAddonItems = data.map(({ addonItem }, index) => ({
        ...addonItem,
        orderBy: index + 1,
      }));
      reorder(reorderedLocalAddonItems);
    },
    [reorder]
  );

  const handleUpdateAddonItems = useCallback(
    (newItemsMeta: LocalAddonItemsMeta) => {
      updateAddonItems(addon.id, newItemsMeta);
      helpers.setValue(newItemsMeta);
    },
    [addon.id, helpers, updateAddonItems]
  );

  const handleCreateNewAfterUpdate = useCallback(() => {
    setTimeout(enableCreateNewAfterUpdate, 0);
  }, [enableCreateNewAfterUpdate]);

  const [togglingAddonItemId, setTogglingAddonItemId] = useState<null | string>(
    null
  );

  const [
    isAutoenableSettingsModalOpen,
    openAutoenableSettingsModal,
    closeAutoenableSettingsModal,
  ] = useOpenClose(false);

  const toggleWithAutoenable = useCallback(
    (addonItemId: string) => {
      const addonItem = localAddonItems.find(({ id }) => id === addonItemId);

      if (addonItem!.active) {
        openAutoenableSettingsModal();
        setTogglingAddonItemId(addonItemId);
      } else {
        toggle(addonItemId, 0);
      }
    },
    [localAddonItems, openAutoenableSettingsModal, toggle]
  );

  const toggleNewAddonItemWithAutoenable = useCallback(() => {
    newAddonItem!.active
      ? openAutoenableSettingsModal()
      : toggleNewAddonItem(0);
  }, [newAddonItem, openAutoenableSettingsModal, toggleNewAddonItem]);

  const finishAutoenable = useCallback(
    (autoenableTimestamp: number) => {
      newAddonItem
        ? toggleNewAddonItem(autoenableTimestamp)
        : toggle(togglingAddonItemId!, autoenableTimestamp);
      closeAutoenableSettingsModal();
      setTogglingAddonItemId(null);
    },
    [
      closeAutoenableSettingsModal,
      newAddonItem,
      toggle,
      toggleNewAddonItem,
      togglingAddonItemId,
    ]
  );

  const handleSkipAutoenable = useCallback(() => finishAutoenable(0), [
    finishAutoenable,
  ]);

  const handleSaveAutoenable = useCallback(
    (autoenableDateTime: Date) =>
      finishAutoenable(autoenableDateTime.getTime()),
    [finishAutoenable]
  );

  useDidUpdate(() => {
    handleUpdateAddonItems({
      localAddonItems,
      newAddonItems,
      removedAddonItems,
      changedAddonItems,
    });
  }, [localAddonItems]);

  useEffect(() => {
    activeAddonItemsSettingsVar({
      prepareNewAddonItem,
    });
    return () => {
      activeAddonItemsSettingsVar(null);
    };
  }, [prepareNewAddonItem]);

  useEffect(() => {
    if (!shouldCreateNewAfterUpdate) return;

    if (isPreparingNewAvailable) prepareNewAddonItem();
    disableCreateNewAfterUpdate();
  }, [
    disableCreateNewAfterUpdate,
    isPreparingNewAvailable,
    prepareNewAddonItem,
    shouldCreateNewAfterUpdate,
  ]);

  return (
    <React.Fragment key={addonItemsSettingsKey}>
      {!localAddonItems.length && !newAddonItem && <AddonItemsEmptyList />}

      <SortableList<RerankableEntity<AddonItemProps>>
        data={localAddonItems.map((localAddonItem) => {
          return {
            addonsSettingsStrategy,
            id: localAddonItem.id,
            draggableType: `MenuAddonItem-${addon.id}`,
            addon,
            addonItem: localAddonItem,
            priceListData: generatePriceListData(
              addonStrategy,
              localAddonItem,
              visiblePriceLevels,
              visiblePriceLevelAddonItems
            ),
            isCloningDisabled: addonType === AddonTypeEnum.priceLevel,
            isUpdateProcessing,
            onClone: clone,
            onDelete: withProtectionFromDisabledPriceLevelAddonItems(remove),
            onToggle: withProtectionFromDisabledPriceLevelAddonItems(
              toggleWithAutoenable
            ),
            onChangeForm: handleChangeForm,
            createNewAfterUpdate: handleCreateNewAfterUpdate,
          };
        })}
        ItemComponent={AddonItem}
        onChangeOrder={handleChangeOrder}
      />

      {newAddonItem && (
        <AddonItem
          addonsSettingsStrategy={addonsSettingsStrategy}
          addonItem={newAddonItem}
          priceListData={generatePriceListData(
            addonStrategy,
            newAddonItem,
            visiblePriceLevels,
            visiblePriceLevelAddonItems
          )}
          isCloningDisabled={addonType === AddonTypeEnum.priceLevel}
          isUpdateProcessing={isUpdateProcessing}
          isNew
          onClone={noop}
          onDelete={clearNewAddonItem}
          onToggle={toggleNewAddonItemWithAutoenable}
          onChangeForm={createNewAddonItem}
          createNewAfterUpdate={handleCreateNewAfterUpdate}
        />
      )}
      {meta.error && <div className={styles.errorMessage}>{meta.error}</div>}

      {isActivePriceLevelAddonItemAlertOpen && (
        <MmsAlert
          message={t("addons:you-must-have-at-least-1-addon-price-level")}
          closeBtnText={t("common:ok")}
          onCloseBtnClick={closeActivePriceLevelAddonItemsAlert}
        />
      )}

      {isAutoenableSettingsModalOpen && (
        <AutoenableSettings
          uniqId="toggle-addon-item-autoenable-settings"
          onSkip={handleSkipAutoenable}
          onSave={handleSaveAutoenable}
        />
      )}
    </React.Fragment>
  );
};
