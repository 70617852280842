import { ApolloError } from "@apollo/client";
import { ModelCategoryItemsIds } from "@model/DAO/MenuCategory";
import {
  ParsePublishValidationErrorsResult,
  ValidationErrorItem,
} from "@model/helperTypes/errors";
import { MenuAssetEnum } from "@model/helperTypes/MenuAsset";
import { getIsPartner } from "@utils/url";

// todo: need to refactor this hook because of new Error Handling feature
export const parsePublishValidationErrors = (
  categoriesItemsIds: ModelCategoryItemsIds[],
  errors: ApolloError
): ParsePublishValidationErrorsResult => {
  let { menu_category, menu_category_item, menu_item } =
    errors?.graphQLErrors[0]?.extensions?.validation ||
    errors?.graphQLErrors[0]?.extensions?.data ||
    {};

  if (menu_item) {
    const flatItems = categoriesItemsIds.reduce<ModelCategoryItemsIds["items"]>(
      (accum, category) => [...accum, ...category.items],
      []
    );

    menu_category_item = Object.keys(menu_item).reduce(
      (accum: ValidationErrorItem, item: string) => {
        const flatItem = flatItems.find(({ id }) => id === item);
        const key = flatItem!.categoryId;
        // if accum already has a property with this key - return the previous value merged with the new one.
        // Otherwise return only new value
        return {
          ...accum,
          [key]: accum[key]
            ? [...accum[key], ...menu_item[item]]
            : [...menu_item[item]],
        };
      },
      {}
    );
  }

  return { menu_category, menu_category_item, menu_item };
};

export const generateMenuAssetUrl = (
  iwaiterId: number,
  id: number | null,
  originType?: MenuAssetEnum
) => {
  const isPartner = getIsPartner();
  const baseUrl = `/${isPartner ? "partner" : "restaurant"}/${iwaiterId}/menu/`;

  switch (originType) {
    case MenuAssetEnum.category:
      return `${baseUrl}category/iwaiterid/${id || "notSetYet"}`;
    case MenuAssetEnum.menu_item:
      return `${baseUrl}menuitem/iwaiterid/${id || "notSetYet"}`;
    case MenuAssetEnum.addon:
      return `${baseUrl}addon/iwaiterid/${id || "notSetYet"}`;
    default:
      return `${baseUrl}addonitem/iwaiterid/${id || "notSetYet"}`;
  }
};
