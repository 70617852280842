import { css } from "@emotion/css";
import { themeColors } from "@theme/colors";

export const styles = {
  container: css`
    height: 100%;
  `,
  formTitle: css`
    margin-top: 12px;
    margin-bottom: 0;
  `,
  errorMessage: css`
    margin-top: 10px;

    color: ${themeColors.red};
    font-size: 12px;
  `,
};
