import { ModelLocalAddon, ModelMenuAddon } from "@model/DAO/MenuAddon";
import { ModelMenuItem } from "@model/DAO/MenuItem";
import { AddonStrategyEnum, AddonTypeEnum } from "@model/helperTypes/Addon";
import { AddonsSettingsStrategyEnum } from "@model/helperTypes/AddonsSettings";
import { PriceLevelPrices } from "@model/helperTypes/PriceLevelPrices";
import { pickBy } from "ramda";
import { getVisiblePriceLevelPrices, getVisiblePriceLevels } from "./AddonItem";

export const determineAddonType = ({
  priceLevel,
  selectOne,
}: ModelLocalAddon): AddonTypeEnum => {
  switch (true) {
    case priceLevel && !selectOne:
      return AddonTypeEnum.priceLevel;
    case !priceLevel && selectOne:
      return AddonTypeEnum.selectOne;
    case !priceLevel && !selectOne:
      return AddonTypeEnum.selectMultiple;
    default:
      throw new Error("Undefined addon type");
  }
};

export const decomposeAddonType = (
  addonType: AddonTypeEnum
): { priceLevel: boolean; selectOne: boolean } => {
  switch (addonType) {
    case AddonTypeEnum.priceLevel:
      return { priceLevel: true, selectOne: false };
    case AddonTypeEnum.selectOne:
      return { priceLevel: false, selectOne: true };
    case AddonTypeEnum.selectMultiple:
      return { priceLevel: false, selectOne: false };
    default:
      throw new Error("Undefined addon type");
  }
};

export const checkIsAddonNew = (addonId: string): boolean =>
  !!addonId.match(/^(new-addon|cloned)-/);

export const checkIsAddonTempNew = (addonId: string): boolean =>
  !!addonId.match(/^temp-new-addon-/);

export const checkIsPriceLevelProp = (val: string, key: string) =>
  !!key.match(/^priceLevel\d$/);

export const checkIsPriceProp = (val: string, key: string) =>
  !!key.match(/^price$/);

export const getPriceLevelPrices = (item: Object): PriceLevelPrices[] =>
  Object.entries(pickBy(checkIsPriceLevelProp, item)) as any;

export const convertObjectPricesToNumbers = (item: Object): Object => {
  const allPriceProps = {
    ...(pickBy(checkIsPriceLevelProp, item) as any),
    ...(pickBy(checkIsPriceProp, item) as any),
  };
  const numberizePrices = Object.entries<string>(
    allPriceProps
  ).map(([key, value]) => [key, parseFloat(value ?? 0)]);
  const newObject: Object = { ...item, ...Object.fromEntries(numberizePrices) };

  return newObject;
};

export const determineAddonStrategy = (
  addonsSettingsStrategy: AddonsSettingsStrategyEnum,
  priceLevel: boolean,
  priceLevelDependent: boolean
): AddonStrategyEnum => {
  switch (addonsSettingsStrategy) {
    case AddonsSettingsStrategyEnum.category: {
      if (priceLevel) {
        return AddonStrategyEnum.PRICE_LEVEL__CATEGORY_OWNER;
      }
      return priceLevelDependent
        ? AddonStrategyEnum.REGULAR__PRICE_LEVEL_DEPENDENT
        : AddonStrategyEnum.REGULAR__PRICE_LEVEL_INDEPENDENT;
    }
    case AddonsSettingsStrategyEnum.menu_item_attached:
    case AddonsSettingsStrategyEnum.menu_item_detached: {
      if (priceLevel) {
        return AddonStrategyEnum.PRICE_LEVEL__MENU_ITEM_OWNER;
      }
      return priceLevelDependent
        ? AddonStrategyEnum.REGULAR__PRICE_LEVEL_DEPENDENT
        : AddonStrategyEnum.REGULAR__PRICE_LEVEL_INDEPENDENT;
    }
    default:
      throw new Error(
        "utils -> Addon -> determineAddonStrategy(): Wrong addonsSettingsStrategy"
      );
  }
};

export const convertMenuAddonToLocalAddon = ({
  __typename,
  ...addon
}: ModelMenuAddon): ModelLocalAddon => {
  let localAddonItems = addon.items.map(({ __typename, ...addonItem }) => ({
    ...addonItem,
  }));

  return {
    ...addon,
    autoenableTimestamp: 0,
    itemsMeta: {
      localAddonItems,
      newAddonItems: [],
      changedAddonItems: [],
      removedAddonItems: [],
    },
  };
};

interface IncomingOptions {
  priceLevelAddon?: ModelMenuAddon;
  menuItemPriceLevelPrices: PriceLevelPrices[];
  menuItemPrice: ModelMenuItem["price"];
}

export interface MenuItemAddonsFormData {
  name: string;
  value: string;
}

export const generateFormDataForMenuItemAddonsSection = ({
  priceLevelAddon,
  menuItemPriceLevelPrices,
  menuItemPrice,
}: IncomingOptions): MenuItemAddonsFormData[] => {
  if (!priceLevelAddon?.items.length) {
    return [
      {
        name: "price",
        value: menuItemPrice,
      },
    ];
  }

  const visiblePriceLevels = getVisiblePriceLevels(priceLevelAddon.items);
  const visiblePriceLevelPrices = getVisiblePriceLevelPrices(
    visiblePriceLevels,
    menuItemPriceLevelPrices
  );

  return visiblePriceLevelPrices.map(([priceLevel, value]) => ({
    name: priceLevel,
    value,
  }));
};

export const separatePriceLevelAddonFromRest = (
  activeAddons: ModelMenuAddon[]
): [ModelMenuAddon[], ModelMenuAddon?] => {
  const restAddons = activeAddons.filter(({ priceLevel }) => !priceLevel);
  const priceLevelAddon = activeAddons.find(({ priceLevel }) => priceLevel);

  return [restAddons, priceLevelAddon];
};
